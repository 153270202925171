<template>
  <b-row>
    <b-col cols="12">
        <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="hasTopup ? 'Selesai' : 'Selesai'"
        back-button-text="Sebelumnya"
        next-button-text="Selanjutnya"
        :start-index="start_index"
        class="mb-3"
        @on-complete="formSubmitted"
        >

        <!-- accoint details tab -->
        <tab-content
            title="Informasi Pengguna"
            :before-change="validationFormInfoPengguna"
        >
            <validation-observer
            ref="accountRules"
            tag="form"
            >
            <b-row>
                <b-col
                cols="12"
                class="mb-2"
                >
                <h5 class="mb-0">
                    Informasi Pengguna
                </h5>
                <small class="text-muted">
                    Lengkapi Informasi Anda
                </small>
                </b-col>
                <b-col md="6">
                <b-form-group
                    label="Nama Depan"
                    label-for="name"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="firstName"
                    rules="required"
                    :custom-messages="customMessages"
                    >
                    <b-form-input
                        id="firstName"
                        v-model="firstName"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Masukan Nama Depan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <b-col md="6">
                <b-form-group
                    label="Nama Belakang"
                    label-for="email"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="lastName"
                    rules="required"
                    :custom-messages="customMessages"
                    >
                    <b-form-input
                        id="lastName"
                        v-model="lastName"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Masukan Nama Belakang"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <b-col md="6">
                <b-form-group
                    label="Tanggal Lahir"
                    label-for="dateOfBirth"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="Tanggal Lahir"
                    rules="required"
                    :custom-messages="customMessages"
                    >
                    <date-picker
                        v-model="dateOfBirth"
                        format="DD MMMM, YYYY"
                        type="date"
                        :input-class="(Boolean(errors[0]))? 'form-control is-invalid-only-border no-x' : 'form-control'"
                        value-type="YYYY-MM-DD"
                        placeholder="Masukan Tanggal Lahir"
                        style="width: 100%;"
                        :disabled-date="notAfterToday"
                        >
                    </date-picker>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <b-col md="6">
                <b-form-group
                    label="Jenis Kelamin"
                    label-for="Gender"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="Gender"
                    vid="Gender"
                    rules="required"
                    :custom-messages="customMessages"
                    >
                    <div class="demo-inline-spacing">
                        <b-form-radio
                        v-model="gender"
                        name="gender"
                        value="Laki-Laki"
                        >
                        Laki-Laki
                        </b-form-radio>
                        <b-form-radio
                        v-model="gender"
                        name="gender"
                        value="Perempuan"
                        >
                        Perempuan
                        </b-form-radio>
                    </div>

                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
            </b-row>
            </validation-observer>
        </tab-content>

        <!-- profil bisnis tab -->
        <tab-content
            title="Profil Bisnis"
            :before-change="validationFormBusiness"
        >
            <validation-observer
            ref="infoRules"
            tag="form"
            >
            <b-row>
                <b-col
                cols="12"
                class="mb-2"
                >
                <h5 class="mb-0">
                    Profil Bisnis
                </h5>
                <small class="text-muted">Lengkapi Profil Bisnis Anda</small>
                </b-col>
                <b-col md="6">
                <b-form-group
                    label="Nama Bisnis"
                    label-for="bisnis-name"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="Nama Bisnis"
                    rules="required"
                    :custom-messages="customMessages"
                    >
                    <b-form-input
                        id="bisnis-name"
                        v-model="companyName"
                        placeholder="Masukan Nama Bisnis"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <b-col md="6">
                <b-form-group
                    label="Website Bisnis (Optional)"
                    label-for="website-business-name"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="Website Bisnis"
                    rules="url"
                    :custom-messages="customMessages"
                    >
                    <b-form-input
                        id="website-business-name"
                        v-model="companySite"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Masukan Website Bisnis"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>

                <b-col md="6">
                <validation-provider
                    #default="{ errors }"
                    name="Tipe"
                    rules="required"
                    :custom-messages="customMessages"
                >
                    <b-form-group
                    label="Tipe"
                    label-for="tipe"
                    :state="errors.length > 0 ? false:null"
                    >
                    <v-select
                        id="tipe"
                        v-model="ownershipTypeID"
                        :options="ownership_type"
                        placeholder="Pilih Tipe Kepemilikan Usaha"
                        label="name"
                    >
                        <span slot="no-options">
                            Data tidak ditemukan
                        </span>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                </b-col>
                <b-col md="6">
                <validation-provider
                    #default="{ errors }"
                    :name="placeholderOwnerType"
                    :rules="
                        ownershipTypeID != null && ownershipTypeID.id == 2 ? 'required' : ''
                    "
                    :custom-messages="customMessages"
                >
                    <b-form-group
                    :label="placeholderOwnerType"
                    label-for="npwp"
                    :state="errors.length > 0 ? false:null"
                    >
                    <b-form-input
                        id="npwp"
                        v-model="npwp"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="'Masukan ' + placeholderOwnerType"
                        v-mask="'##.###.###.#-###.###'"
                    />
                    <b-form-invalid-feedback :state="errNpwp.length > 0 ? false:null">{{ errNpwp }}</b-form-invalid-feedback>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                </b-col>

                <b-col md="6">
                <validation-provider
                    #default="{ errors }"
                    name="Provinsi"
                    rules="required"
                    :custom-messages="customMessages"
                >
                    <b-form-group
                    label="Provinsi"
                    label-for="provinsi"
                    :state="errors.length > 0 ? false:null"
                    >
                    <v-select
                        id="provinsi"
                        v-model="province"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="list_province"
                        placeholder="Pilih Provinsi"
                        label="name"
                    >
                        <span slot="no-options">
                            Data tidak ditemukan
                        </span>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                </b-col>
                <b-col md="6">
                <validation-provider
                    #default="{ errors }"
                    name="Kabupaten"
                    rules="required"
                    :custom-messages="customMessages"
                >
                    <b-form-group
                    label="Kabupaten"
                    label-for="kabupaten"
                    :state="errors.length > 0 ? false:null"
                    >
                    <v-select
                        id="kabupaten"
                        v-model="city"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="list_city"
                        placeholder="Pilih Kabupaten / Kota"
                        label="name"
                    >
                        <span slot="no-options">
                            Data tidak ditemukan
                        </span>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                </b-col>

                <b-col md="12">
                <validation-provider
                    #default="{ errors }"
                    name="Alamat"
                    rules="required"
                    :custom-messages="customMessages"
                >
                    <b-form-group
                    label="Alamat"
                    label-for="alamat"
                    :state="errors.length > 0 ? false:null"
                    >
                    <b-form-textarea
                        id="alamat"
                        placeholder="Masukan Alamat"
                        rows="8"
                        v-model="address"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                </b-col>

                <b-col md="6">
                <validation-provider
                    #default="{ errors }"
                    name="Jenis Usaha"
                    rules="required"
                    :custom-messages="customMessages"
                >
                    <b-form-group
                    label="Jenis Usaha"
                    label-for="jenis-usaha"
                    :state="errors.length > 0 ? false:null"
                    >
                    <v-select
                        id="jenis-usaha"
                        v-model="companyTypeID"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="list_company_type"
                        placeholder="Pilih Jenis Usaha"
                        label="name"
                    >
                        <span slot="no-options">
                            Data tidak ditemukan
                        </span>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                </b-col>
                <b-col md="6">
                <validation-provider
                    #default="{ errors }"
                    name="Nama Jenis Usaha"
                    rules="required"
                    :custom-messages="customMessages"
                >
                    <b-form-group
                    label="Nama Jenis Usaha"
                    label-for="nama-jenis-usaha"
                    :state="errors.length > 0 ? false:null"
                    >
                    <v-select
                        v-if="!companyTypeNameFreeText"
                        id="nama-jenis-usaha"
                        v-model="companyTypeNameID"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="list_company_subtype"
                        placeholder="Pilih Nama Jenis Usaha"
                        label="name"
                    >
                        <span slot="no-options">
                            Data tidak ditemukan
                        </span>
                    </v-select>
                    <b-form-input
                        v-if="companyTypeNameFreeText"
                        id="nama-jenis-usaha"
                        v-model="companyTypeNameID"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Masukan Nama Jenis Usaha"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                </b-col>
            </b-row>
            </validation-observer>
        </tab-content>

        <!-- metode beriklan tab  -->
        <tab-content
            title="Metode Beriklan"
            :before-change="validationFormAddress"
        >
            <validation-observer
            ref="addressRules"
            tag="form"
            >
            <b-row>
                <b-col
                cols="12"
                class="mb-2"
                >
                <h5 class="mb-0">
                    Jenis Metode Beriklan
                </h5>
                </b-col>

                <b-col
                cols="12"
                class="mb-2"
                >
                <h5 class="mb-0">
                    Beli Kredit
                </h5>
                </b-col>
                <b-col cols="12" class="text-justify">
                Kredit iklan akan digunakan untuk pembayaran bagi influencers yang mengikuti campaign kamu. Campaign baru kamu akan aktif setelah kamu melakukan pembelian kredit iklan.
                <b-row>
                    <b-col cols="12" class="text-right">
                    <!-- <b-link
                        class="btn btn-primary"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        v-b-modal.modal-topup
                    >
                        Beli Kredit Iklan
                    </b-link> -->
                    </b-col>
                </b-row>
                </b-col>

                <b-col
                cols="12"
                class="mb-2"
                >
                <br>
                <h5 class="mb-0">
                    Ajukan Verified
                </h5>
                </b-col>
                <b-col cols="12" class="text-justify">Dengan mengajukan akun sebagai verified, kamu tidak perlu membeli kredit iklan karena pembayaran akan dilakukan dengan sistem termin.
                <b-row>
                    <b-col cols="12" class="text-right">
                    <!-- <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        v-b-modal.modal-faq
                    >
                        Ajukan Verified
                    </b-button> -->
                    </b-col>
                </b-row>
                </b-col>
            </b-row>
            </validation-observer>
        </tab-content>
        </form-wizard>
    </b-col>

    <b-modal
    ref="modal-syarat"
    centered
    title="Lengkapi Profil"
    ok-only
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    ok-title="Setuju"
    size="lg"
    >
        <b-card-text>
            Silahkan Lengkapi Data Terlebih Dahulu
        </b-card-text>
    </b-modal>

    <b-modal
    id="modal-topup"
    ref="modal-topup"
    centered
    title="Top Up Kredit Iklan"
    size="lg"
    >
      <div>
        <b-form inline>
            <label class="sr-only" for="inline-form-input-username">Topup</label>
            <b-input-group prepend="Rp" class="mb-2 mr-sm-2 mb-sm-0 col-12">
                <b-form-input type="number" v-model="topup" id="inline-form-input-username" placeholder="Masukan Nominal Topup"></b-form-input>
            </b-input-group>

            <!-- <label class="sr-only" for="inline-form-input-name">Name</label>
            <v-select
                    id="tipe"
                    :options="list_bank"
                    placeholder="Pilihan Metode Pembayaran"
                    v-model="payment_method"
                    label="name"
                    class="mb-2 mr-sm-2 mb-sm-0 col-5"
                    :disabled="!topup || topup < 10000"
                >
                <span slot="no-options">
                    Data tidak ditemukan
                </span>
            </v-select> -->
        </b-form>
        </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right"
            :disabled="checkFormTopUp()"
            @click="topUp()"
          >
            Top Up
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
    ref="modal-lewati"
    centered
    title="Lengkapi Profil"
    no-close-on-esc
    no-close-on-backdrop
    ok-title="Ya, Selesai"
    cancel-title="Batal"
    @ok="handleLewati"
    size="md"
    >
        <b-card-text>
            Apakah data yang dimasukkan sudah benar?
        </b-card-text>
    </b-modal>

    <b-modal
    id="modal-faq"
    centered
    title="FAQ/Pusat Bantuan"
    ok-only
    ok-title="Oke"
    size="md"
    >
        <b-card-text>
            Kamu bisa mengajukan verified dengan mengisi form pada halaman FAQ
        </b-card-text>
    </b-modal>
  </b-row>
</template>

<style>
.is-invalid-only-border {
    border-color: red;
}
</style>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BFormDatepicker,
  BFormTextarea,
  BButton,
  VBModal,
  BModal,
  BCardText,
  BLink,
  BInputGroup,
  BForm
} from 'bootstrap-vue'
import { required, email } from '@validations'
import axios from '@axios'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {mask} from 'vue-the-mask'
import eventBus from '@/libs/eventBus'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadio,
    BFormDatepicker,
    BFormTextarea,
    BButton,
    BModal,
    BCardText,
    BLink,
    BInputGroup,
    DatePicker,
    BForm
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    mask,
  },
  data() {
    return {
      config:{
        payment : 'advertiser/topup'
      },
      firstName: null,
      lastName: null,
      dateOfBirth: null,
      gender: null,

      companyName: null,
      companySite: null,
      ownershipTypeID: null,
      npwp: null,
      province: null,
      city: null,
      address: null,
      companyTypeID: null,
      companyTypeNameID: null,

      placeholderOwnerType: 'NPWP',
      companyTypeNameFreeText: false,

      errNpwp: '',

      list_bank: [
          {
            id: 1,
            name: 'BANK BCA'
          },
          {
            id: 2,
            name: 'BANK Mandiri'
          },
          {
            id: 3,
            name: 'BANK BNI'
          }
      ],
      ownership_type: [],
      list_province: [],
      list_city: [],
      list_company_type: [],
      list_company_subtype: [],

      topup: null,
      payment_method: null,
      hasTopup: false,

      start_index: 0,

      customMessages: {
        required: 'Harus diisi',
        url: 'Alamat website tidak valid'
      },

      selectedContry: '',
      selectedLanguage: '',
      required,
      email,
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
      userData: {
        advertiser: {
          firstName: '',
          lastName: '',
          companyName: '',
          npwp: '',
          province: '',
          city: '',
          address: '',
          companyType: '',
          companyTypeName: '',
          dateOfBirth: '',
          gender: ''
        }
      }
    }
  },
  beforeMount(){
      let _ = this
      _.updateLastState()
      
      _.getOwnershipType()
      _.getProvince()
      _.getCompanyType()
  },
  mounted() {
      let _ = this
      
      this.$refs['modal-syarat'].show()

      _.getInfoPengguna()
      _.getInfoBusiness()
  },
  methods: {
    formSubmitted() {
      let _ = this
      if ( _.hasTopup ) {
          _.$router.push('/')
      } else {
          this.$refs['modal-lewati'].show()
      }
    },

    getInfoPengguna() {
        let _ = this
        let userData = JSON.parse(localStorage.getItem('userData')).advertiser
        let dob = userData.dateOfBirth.split('-')
        _.firstName = userData.firstName
        _.lastName = userData.lastName
        _.dateOfBirth = dob[2] + '-' + dob[1] + '-' + dob[0]
        _.gender = userData.gender
    },
    getInfoBusiness() {
        let _ = this
        let userData = JSON.parse(localStorage.getItem('userData')).advertiser
        _.companyName = userData.companyName
        _.companySite = userData.companySite
        _.npwp = userData.npwp
        _.province = userData.province
        _.city = userData.city
        _.address = userData.address
        _.companyTypeID = userData.companyTypeID
        _.companyTypeNameID = userData.companyTypeNameID
    },

    updateLastState() {
        let _ = this
        let lastState = _.$store.state.digirans.lastRegisterStep + 1
        _.start_index = lastState
    },
    validationFormInfoPengguna() {
      let _ = this
      return new Promise((resolve, reject) => {
        _.$refs.accountRules.validate().then(success => {
          if (success) {
            axios.put('/advertiser/register/user-info', {
                    firstName: _.firstName, lastName: _.lastName, dateOfBirth: _.dateOfBirth, gender: _.gender
                })
                .then(res => {
                    console.log(res)
                    resolve(true)
                    _.$store.dispatch('digirans/UPDATE_LAST_PROFILE', 0)
                    _.userData.advertiser.firstName = _.firstName
                    _.userData.advertiser.lastName = _.lastName
                    _.userData.advertiser.dateOfBirth = _.dateOfBirth
                    _.userData.advertiser.gender = _.gender
                })
                .catch(err => {
                    console.log(err)
                    reject()
                })
          } else {
            reject()
          }
        })
      })
    },
    validationFormBusiness() {
      let _ = this
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            axios.put('/advertiser/register/business-info', {
                    companyName: _.companyName,
                    companySite: _.companySite,
                    ownershipTypeID: _.ownershipTypeID.id,
                    npwp: _.sanitizeNPWP(),
                    province: _.province.name,
                    city: _.city.name,
                    address: _.address,
                    companyTypeID: _.companyTypeID.id,
                    companyTypeNameID: _.companyTypeNameFreeText ? _.companyTypeNameID : _.companyTypeNameID.id
                })
                .then(res => {
                    console.log(res)
                    _.userData.advertiser.companyName = _.companyName
                    _.userData.advertiser.companySite = _.companySite
                    _.userData.advertiser.npwp = _.npwp
                    _.userData.advertiser.province = _.province.name
                    _.userData.advertiser.city = _.city.name
                    _.userData.advertiser.address = _.address
                    _.userData.advertiser.companyType = _.companyTypeID
                    _.userData.advertiser.companyTypeName = _.companyTypeNameID
                    _.$store.dispatch('digirans/UPDATE_LAST_PROFILE', 1)
                    resolve(true)
                })
                .catch(err => {
                    let  errors = err.response.data.message.errors
                    if ( errors.length > 0 ) {
                        errors.forEach(er => {
                            if(er.param == 'npwp') {
                                _.errNpwp = er.msg
                            }
                        })
                        
                    }
                    reject()
                })
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    getOwnershipType() {
        let _ = this
        axios.get('/misc/types/ownership-types')
        .then(resp => {
            _.ownership_type = resp.data.data
        })
        .catch(err => {
            console.log(err.response)
        })
    },
    getProvince() {
        let _ = this
        axios.get('/misc/types/provinces')
        .then(resp => {
            _.list_province = resp.data.data
        })
        .catch(err => {
            console.log(err.response)
        })
    },
    getCity(provinceID) {
        let _ = this
        
        axios.get('/misc/types/city/' + provinceID)
            .then(resp => {
                _.list_city = resp.data.data
            })
            .catch(err => {
                console.log(err.response)
            })
    },
    getCompanyType() {
        let _ = this

        axios.get('misc/types/company-types')
            .then(resp => {
                _.list_company_type = resp.data.data
            })
            .catch(err => {
                console.log(err.response)
            })
    },
    getSubType(companyTypeID) {
        let _ = this
        axios.get('misc/types/company-type-names/' + companyTypeID)
            .then(resp => {
                _.list_company_subtype = resp.data.data
            })
            .catch(err => {
                console.log(err.response)
            })
    },

    topUp() {
        let _ = this
        // axios.put('/advertiser/register/first-topup', {
        //     creditBalance: _.topup
        // }).then(resp => {
        //     _.hasTopup = true
        //     _.$refs['modal-topup'].hide()
        // }).catch(err => {
        //     console.log(err)
        // })
        console.log(this.topup);

        let data = {
          amount : this.topup
        }
        axios
        .post(_.config.payment, data)
        .then((res) => {
          _.$bvToast.toast("Anda akan di redirect ke Payment", {
            title: "Sukses",
            variant: "success",
            solid: true,
          });
          setTimeout(() => {
            window.open(res.data.data.redirect_url, "_blank")  
          }, 3000);
          this.topup = 0
             this.$refs["modal-topup"].hide();
        })
        .catch((e) => {
          let vm = this;
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
          } else {
            vm.messages = e.response.data.message;
          }
        });
    },
    checkFormTopUp() {
        let _ = this
        if ( _.topup === null || _.topup < 10000 ) {
            return true
        } else {
            return false
        }
    },

    hideModal() {
        let _ = this
        _.$refs['modal-topup'].hide()
    },
    handleLewati() {
        let _ = this
        axios.put('/advertiser/register/first-topup', {
            skip: true
        }).then(resp => {
            console.log('handle Lewati')
            let advertiser = resp.data.data
            console.log(advertiser)
            let userData = JSON.parse(localStorage.getItem('userData'))
            let data = _.userData
            let updateData = {
                ...userData,
                ...data,
                advertiser: {
                    ...advertiser
                },
                lastRegisterStep: 2
            }
            // fullName
            // companyTypeID
            // companyTypeNameID
            localStorage.setItem('userData', JSON.stringify(updateData))
            eventBus.$emit('PROFILE_UPDATED', updateData)
            _.$router.push('/')
        }).catch(err => {
            console.log(err)
        })
    },

    notAfterToday(date) {
      var oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 17);

      return date >= new Date(oneYearFromNow);
    },
    sanitizeNPWP() {
        let _ = this
        let npwp;
        if (this.npwp != null) {
            npwp = _.npwp.replaceAll('.','')
            npwp = npwp.replaceAll('-','')    
        }else{
            npwp = null
        }     

        return npwp
    }
  },
  computed: {
      maxDate() {
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            // 15th in two months
            const maxDate = new Date(today)
            maxDate.setDate(maxDate.getDate() - 1)

            return maxDate;
      },
      textFinishWizard() {
          JSON.parse(localStorage.getItem('userData'))
      }
  },
  watch: {
      'province': function(newValue, oldValue) {
          let _ = this
          _.city = null
          if ( oldValue ) {
                if ( oldValue.id != newValue.id ) {
                    _.getCity(newValue.id)
                }
          } else {
              _.getCity(newValue.id)
          }
      },
      'companyTypeID': function(newValue, oldValue) {
          let _ = this
          _.companyTypeNameID = null
          if ( oldValue ) {
                if ( oldValue.id != newValue.id ) {
                    _.companyTypeNameFreeText = newValue.name == 'Lain-Lain'
                    _.getSubType(newValue.id)
                }
          } else {
              _.getSubType(newValue.id)
          }
          
      },
      'ownership_type': function(newValue) {
          let _ = this
          let userData = JSON.parse(localStorage.getItem('userData')).advertiser
          _.ownershipTypeID = newValue.filter(ot => {
                return ot.id == userData.ownershipTypeID
          })[0]
      },
      'list_company_type': function(newValue) {
          let _ = this
          let userData = JSON.parse(localStorage.getItem('userData')).advertiser
          _.companyTypeID = newValue.filter(ot => {
                return ot.id == userData.companyTypeID
          })[0]
          _.getSubType(userData.companyTypeID)
      },
      'list_company_subtype': function(newValue) {
          let _ = this
          let userData = JSON.parse(localStorage.getItem('userData')).advertiser
          if ( userData ) {
              if ( userData.companyTypeNameID ) {
                  let typeName = newValue.filter(ot => {
                    return ot.id == userData.companyTypeNameID
                  })[0]

                  _.companyTypeNameID = _.companyTypeID.name == 'Lain-Lain' ? typeName.name : typeName
              }
          }
      },
      'list_province': function(newValue) {
          let _ = this
          let userData = JSON.parse(localStorage.getItem('userData')).advertiser
          _.province = newValue.filter(ot => {
                return ot.name == userData.province
          })[0]
          _.getCity( _.province.id)
      },
      'list_city': function(newValue) {
          let _ = this
          let userData = JSON.parse(localStorage.getItem('userData')).advertiser
          _.city = newValue.filter(ot => {
                return ot.name == userData.city
          })[0]
      },
      'ownershipTypeID': function(newValue, oldValue) {
          if ( newValue ) {
              this.placeholderOwnerType = 'NPWP ' + newValue.name
              if ( oldValue !== null ) {
                  this.npwp = null
              }
          }
      }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>